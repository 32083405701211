import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { StaticImage as Img } from 'gatsby-plugin-image'
import Content from './style'
import SectionTitle from './Components/SectionTitle'
import { Link } from "gatsby"

export default function ContentSectionOne() {
  return (
    <Content>
      <Container>
        <Content.InnerWrapper>
          <Row className="align-items-center justify-content-center">
            <Col xs="12" className="col-lg-4 col-md-8 col-xs-11 text-center text-lg-start">
              {/* Section Title */}
              <SectionTitle
                subTitle=""
                title="Ofrecemos una experiencia única 
                en todos nuestros 
                productos y servicios"
                text="Conoce más sobre nuestra forma de trabajo, comunicación, KPI’s y resultados."
                titleProps={{ mb: "30px" }}
                subTitleProps={{ mb: "25px" }}
              />
              <Content.Button className="contact_btn_home" background="#000000" rounded={true} mt="25px">
              <Link to="/contact">Contacto</Link>
              </Content.Button>
              
              {/* <button className="contact_btn_home btn"><Link to="/contact">Contacto</Link></button> */}
            </Col>
            <Col xs="12" className="col-xxl-5 col-lg-4 col-md-6 col-xs-9">
              <Content.Image mt="30px" mb="30px" mbMD="0" mtMD="0">
                <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1642006153/helios/HOME%20HELIOSDG/experiencia_2x_ixl2w3.png" alt="content" layout="fullWidth" placeholder="blurred" />
              </Content.Image>
            </Col>
            <Col xs="12" className="col-xl-3 col-lg-4 col-md-6 col-xs-9 order-3">
              <Content.WidgetsWrapper mb="-10px" mtMD="40px" mtLG="0" mrXS="70px" mrMD="30px" mrLG="0">
                {/* Single Services */}
                <Content.Widget pb="10px">
                  <Content.WidgetTitle className="txt_black">Nuestros Clientes</Content.WidgetTitle>
                  <Content.WidgetText className="txt_black">Buscamos satisfacer las necesidades de los clientes a través de nuestros productos y servicios.</Content.WidgetText>
                </Content.Widget>
                {/*/ .Single Services */}
                {/* Single Services */}
                <Content.Widget pb="10px">
                  <Content.WidgetTitle className="txt_black">Nuestros Productos</Content.WidgetTitle>
                  <Content.WidgetText className="txt_black">Nuestros productos cuentan con las tecnologías más avanzadas.</Content.WidgetText>
                </Content.Widget>
                {/*/ .Single Services */}
                {/* Single Services */}
                <Content.Widget pb="10px">
                  <Content.WidgetTitle className="txt_black">Nuestros Servicios</Content.WidgetTitle>
                  <Content.WidgetText className="txt_black">Proporcionamos un servicio excelente.</Content.WidgetText>
                </Content.Widget>
                {/*/ .Single Services */}
              </Content.WidgetsWrapper>
            </Col>
          </Row>
        </Content.InnerWrapper>
      </Container>
    </Content>

  )
}